import { Grid, Toolbar } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import LogoClickable from '../../../layouts/buy/LogoClickable'
import Head from '../../../layouts/Head'
import { LoadingPageProps } from '../../LoadingPage'
import Spinner from '../../Spinner'
import CheckoutAppBar from '../layout/CheckoutAppBar'

export type CheckoutLoadingPageProps = LoadingPageProps

export const CheckoutLoadingPage: React.FC<CheckoutLoadingPageProps> = ({
  title = 'Loading...',
}) => {
  return (
    <>
      <Head />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CheckoutAppBar>
        <Toolbar>
          <LogoClickable />
        </Toolbar>
      </CheckoutAppBar>
      <Grid alignItems="stretch" component="main" container justify="center">
        <Spinner loading />
      </Grid>
    </>
  )
}
